<template>
  <div>
    <report-criteria
      :criteriaRedrawKey="criteriaRedrawKey"
      :populationStore="populationStore"
      :workflowStore="workflowStore"
      :globalSelector="false"
      @characteristics-set="fieldSet('characteristics')"
      @date-range-set="fieldSet('date')"
      @data-segment-set="fieldSet('dataSegment')"
      @gender-set="fieldSet('gender')"
      @age-range-set="fieldSet('ageRange')"
      @patient-status-set="fieldSet('patientStatus')"
      :disabled="visitsLoading || patientsLoading"
    />

    <div v-if="dataType === 'visits'">
      <div v-if="!visitsLoading">
        <dl class="color-secondary rfs-30 fw-m rfs-m-8-t">
          <dt class="inline">Total Encounters:&nbsp;</dt>
          <dd class="inline fw-b">
            <animated-number
              data-total-encounters-test
              :number="
                totalEncountersVisits
                  | formatNumber({ format: 'precision', places: 0 })
              "
              :numberFormat="{ format: 'largeNumberAbbr' }"
            ></animated-number>
          </dd>
        </dl>
        <!-- chartRedrawKey: For charts to resize their component's key has to update anytime we add or remove columns so charts re-render to fit. The normal build-in responsive behavior doesn't apply here. That's why we are combo-ing in the iteration number (in the parent component) with the column number. -->
        <visits-charts
          :key="chartRedrawVisitsFormatted"
          :filterPatientStatus="patientStatusFormatted"
          :countsLoading="visitsLoading"
          :ageRangeChoices="ageRangeChoicesFunc()"
          :genderChoices="genderChoicesFunc()"
          :countData="visitsFiltered"
          :genderData="genderDataVisitCount"
          :ageRangeData="ageRangeDataVisits"
          :ageRangeReadmissions="ageRangeReadmissionsVisits"
          :visitsFilteredPatientStatus="visitsFilteredPatientStatus"
          :ageRangeLos="ageRangeLosVisits"
          :ageRangeDataTotals="ageRangeDataVisitsTotals"
          class="rfs-m-6-t"
        ></visits-charts>
      </div>
      <loader v-if="visitsLoading" />
    </div>
    <div v-if="dataType === 'patients'">
      <div v-if="!patientsLoading">
        <dl class="color-secondary rfs-30 fw-m rfs-m-8-t">
          <dt class="inline">Total Patients:&nbsp;</dt>
          <dd class="inline fw-b">
            <!-- prettier-ignore -->
            <animated-number
                data-total-patients-test
                :number="totalEncountersPatients | formatNumber({ format: 'precision', places: 0 })"
                :numberFormat="{ format: 'largeNumberAbbr' }"
              ></animated-number
              >
          </dd>
        </dl>
        <patients-charts
          :key="chartRedrawPatientsFormatted"
          :filterPatientStatus="patientStatusFormatted"
          :ageRangeChoices="ageRangeChoicesFunc()"
          :genderChoices="genderChoicesFunc()"
          :countData="patientsFiltered"
          :patientStatusCountsPatients="patientStatusCountsPatients"
          :genderData="genderDataPatientCount"
          :ageRangeData="ageRangeDataPatients"
          :totalEncounters="totalEncountersPatients"
          class="rfs-m-6-t"
        ></patients-charts>
      </div>
      <loader v-if="patientsLoading" />
    </div>
  </div>
</template>

<script>
  import ReportCriteria from '@/components/filters/ReportCriteria.vue';
  import AnimatedNumber from '@/components/base/animated_number.vue';

  import { ref, watch, computed } from '@vue/composition-api';

  import { filterArray } from '@/js/utilities';
  import sumBy from 'lodash/sumBy';

  import {
    calculateGenderData,
    calculateAgeRangeByGenderData,
    calculateAgeRangeReadmissions,
    calculateAgeRangeLos,
    calculateAgeRangeData,
    calculatePatientStatusDataVisits,
    calculatePatientStatusDataPatients
  } from '@/data';

  const VisitsCharts = () => import('@/components/popbuilder/VisitsCharts');
  const PatientsCharts = () =>
    import('@/components/popbuilder/PatientsCharts.vue');
  export default {
    components: {
      ReportCriteria,
      VisitsCharts,
      PatientsCharts,
      AnimatedNumber
    },
    props: [
      'populationStore',
      'workflowStore',
      'columnIndex',
      'chartRedrawKey',
      'criteriaRedrawKey',
      'dataType'
    ],
    setup(props, { root }) {
      let visitsFiltered = ref([]);
      let patientsFiltered = ref([]);
      let visitsLoading = ref(true);
      let patientsLoading = ref(true);
      let patientStatusFormatted = ref(props.workflowStore.getPatientStatus());

      watch(props.populationStore.visitsLoading, (currentValue, oldValue) => {
        visitsLoading.value = currentValue;
      });
      watch(props.populationStore.patientsLoading, (currentValue, oldValue) => {
        patientsLoading.value = currentValue;
      });

      watch(props.populationStore.isDirty, (currentValue, oldValue) => {
        if (currentValue) {
          props.populationStore.loadPopulation();
        }
      });

      // Cause the population to locally update when local dirty
      watch(props.populationStore.isDirtyLocal, (currentValue, oldValue) => {
        // If dirty went from false to true
        if (currentValue) {
          patientStatusFormatted.value = props.workflowStore.getPatientStatus();

          visitsFiltered.value = filterArray(
            props.populationStore.getVisitData(),
            props.workflowStore.demographicFiltersVisits
          );

          patientsFiltered.value = filterArray(
            props.populationStore.getPatientData(),
            props.workflowStore.demographicFiltersPatients
          );
        }

        props.populationStore.setDirtyLocal(false);
      });

      // When the visit data is updated, filter and display
      watch(props.populationStore.getVisitData, (currentValue, oldValue) => {
        visitsFiltered.value = filterArray(
          currentValue,
          props.workflowStore.demographicFiltersVisits
        );
      });

      // When the population data is updated, filter and display
      watch(props.populationStore.getPatientData, (currentValue, oldValue) => {
        patientsFiltered.value = filterArray(
          currentValue,
          props.workflowStore.demographicFiltersPatients
        );
      });

      const patientStatusCountsVisits = computed(() => {
        let counts = calculatePatientStatusDataVisits(visitsFiltered.value);
        counts.all = counts.inpatient + counts.outpatient;
        return counts;
      });

      const totalEncountersVisits = computed(() => {
        let count = 0;
        for (let i in patientStatusFormatted.value) {
          if (
            patientStatusCountsVisits.value[patientStatusFormatted.value[i]]
          ) {
            count +=
              patientStatusCountsVisits.value[patientStatusFormatted.value[i]];
          }
        }

        return count;
      });

      const patientStatusCountsPatients = computed(() => {
        let counts = calculatePatientStatusDataPatients(patientsFiltered.value);
        // since a patient can be both in a given time frame, this is not additive like it is for visits
        counts.all = sumBy(patientsFiltered.value, 'count');

        return counts;
      });

      const totalEncountersPatients = computed(() => {
        if (
          patientStatusFormatted.value.includes('inpatient') &&
          patientStatusFormatted.value.includes('outpatient')
        ) {
          return patientStatusCountsPatients.value.all;
        } else if (patientStatusFormatted.value.includes('inpatient')) {
          return patientStatusCountsPatients.value.inpatient;
        } else if (patientStatusFormatted.value.includes('outpatient')) {
          return patientStatusCountsPatients.value.outpatient;
        }

        return 0;
      });

      return {
        visitsFiltered,
        visitsLoading,
        patientsFiltered,
        patientsLoading,
        patientStatusCountsVisits,
        patientStatusCountsPatients,
        patientStatusFormatted,
        totalEncountersVisits,
        totalEncountersPatients
      };
    },
    data() {
      return {};
    },
    methods: {
      async init() {
        // Init the store

        // Default population
        this.populationStore.setPopulationReady();

        this.populationStore.loadSavedPopulationByFilterData(
          this.populationStore.getCurrentPopulationSelection()
        );

        this.$emit("load-global-selector");
      },
      ageRangeChoicesFunc() {
        return this.workflowStore.getAgeRange();
      },
      genderChoicesFunc() {
        return this.workflowStore.getGender();
      },
      genderDataFunc() {
        if (this.patientStatusBoth) {
          return calculateGenderData({
            visits: this.visitsFiltered,
            patients: this.patientsFiltered
          });
        } else if (this.patientStatusEqualsInpatient) {
          return calculateGenderData(
            {
              visits: this.visitsFilteredPatientStatus,
              patients: this.patientsFiltered
            },
            {
              sumBy: 'inpatientCount'
            }
          );
        } else if (this.patientStatusEqualsOutpatient) {
          return calculateGenderData(
            {
              visits: this.visitsFilteredPatientStatus,
              patients: this.patientsFiltered
            },
            {
              sumBy: 'outpatientCount'
            }
          );
        }

        return calculateGenderData({});
      },
      fieldSet(type) {
        this.$emit('local-selector-changed', type);
      }
    },
    computed: {
      noData() {
        return this.visitsFiltered.length == 0;
      },
      criteriaRedrawFormatted() {
        return this.criteriaRedrawKey + '_criteria';
      },
      chartRedrawVisitsFormatted() {
        return this.criteriaRedrawKey + '_visits_chart';
      },
      chartRedrawPatientsFormatted() {
        return this.criteriaRedrawKey + '_patients_chart';
      },
      patientStatusIncludesInpatient() {
        return this.patientStatusFormatted.includes('inpatient');
      },
      patientStatusIncludesOutpatient() {
        return this.patientStatusFormatted.includes('outpatient');
      },
      patientStatusBoth() {
        return (
          this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsInpatient() {
        return (
          this.patientStatusIncludesInpatient &&
          !this.patientStatusIncludesOutpatient
        );
      },
      patientStatusEqualsOutpatient() {
        return (
          !this.patientStatusIncludesInpatient &&
          this.patientStatusIncludesOutpatient
        );
      },
      visitsFilteredPatientStatus() {
        return filterArray(this.visitsFiltered, {
          patientStatus: (patientStatus) =>
            this.patientStatusFormatted.includes(
              patientStatus.toString().toLowerCase()
            )
        });
      },
      genderData() {
        if (this.patientStatusBoth) {
          return calculateGenderData({
            visits: this.visitsFiltered,
            patients: this.patientsFiltered
          });
        } else if (this.patientStatusEqualsInpatient) {
          return calculateGenderData(
            {
              visits: this.visitsFilteredPatientStatus,
              patients: this.patientsFiltered
            },
            {
              sumBy: 'inpatientCount'
            }
          );
        } else if (this.patientStatusEqualsOutpatient) {
          return calculateGenderData(
            {
              visits: this.visitsFilteredPatientStatus,
              patients: this.patientsFiltered
            },
            {
              sumBy: 'outpatientCount'
            }
          );
        }

        return calculateGenderData({});
      },
      genderDataPatientCount() {
        let ret = [];

        ret[0] = this.genderData.malePatientCount;
        ret[1] = this.genderData.femalePatientCount;

        return ret;
      },
      genderDataVisitCount() {
        let ret = [];

        ret[0] = this.genderData.maleVisitCount;
        ret[1] = this.genderData.femaleVisitCount;

        return ret;
      },

      ageRangeDataPatients() {
        if (this.patientStatusBoth) {
          return calculateAgeRangeByGenderData(this.patientsFiltered);
        } else if (this.patientStatusEqualsInpatient) {
          return calculateAgeRangeByGenderData(this.patientsFiltered, {
            sumBy: 'inpatientCount'
          });
        } else if (this.patientStatusEqualsOutpatient) {
          return calculateAgeRangeByGenderData(this.patientsFiltered, {
            sumBy: 'outpatientCount'
          });
        }

        return calculateAgeRangeByGenderData({});
      },
      ageRangeDataVisits() {
        if (this.patientStatusBoth) {
          return calculateAgeRangeByGenderData(this.visitsFiltered);
        }
        return calculateAgeRangeByGenderData(this.visitsFilteredPatientStatus);
      },
      ageRangeDataVisitsTotals() {
        if (this.patientStatusBoth) {
          return calculateAgeRangeData(this.visitsFiltered);
        }
        return calculateAgeRangeData(this.visitsFilteredPatientStatus);
      },
      ageRangeReadmissionsVisits() {
        if (this.patientStatusEqualsInpatient) {
          return calculateAgeRangeReadmissions(
            this.visitsFilteredPatientStatus
          );
        }
        return false; // we only care if it's inpatient only
      },
      ageRangeLosVisits() {
        if (this.patientStatusBoth) {
          return calculateAgeRangeLos(this.visitsFiltered);
        }
        return calculateAgeRangeLos(this.visitsFilteredPatientStatus);
      }
    },
    mounted() {
      this.init();
    }
  };
</script>