<template>
  <span>{{ displayNumber }}</span>
</template>

<script>
  import { formatNumber } from '@/js/utilities';

  export default {
    name: 'AnimatedNumber',
    props: { number: { default: 0 }, numberFormat: { default: null } },
    data() {
      return {
        displayNumber: '0',
        counterNumber: 0,
        interval: false
      };
    },

    mounted() {
      this.counterNumber = this.number ? this.number : 0;
      this.displayNumber = this.numberFormat
        ? formatNumber(this.counterNumber, this.numberFormat)
        : this.counterNumber;
    },

    watch: {
      number() {
        clearInterval(this.interval);

        if (this.number == this.counterNumber) {
          return;
        }
        let changeDivider = 10;
        if (this.counterNumber > 3000) {
          changeDivider = 5;
        }
        if (this.counterNumber > 10000) {
          changeDivider = 2;
        }

        this.interval = window.setInterval(() => {
          if (this.counterNumber != this.number) {
            let change = (this.number - this.counterNumber) / changeDivider;
            change = change >= 0 ? Math.ceil(change) : Math.floor(change);
            this.counterNumber = this.counterNumber + change;
            if (Number.isNaN(this.counterNumber)) {
              this.counterNumber = 0;
            }
            this.displayNumber = this.numberFormat
              ? formatNumber(this.counterNumber, this.numberFormat)
              : this.counterNumber;
          }
        }, 20);
      }
    }
  };
</script>
